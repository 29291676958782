/* ------------------------------------------------------------------ */
/*        HEADING DECLARATION
/* ------------------------------------------------------------------ */



* {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    text-rendering: geometricPrecision;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-touch-callout: none;
    background-repeat: no-repeat;
}

a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;

    &:hover {

        color: $red;

    }
}

html {

    font-size: 1rem;
    color: $black;
    background-color: $red;
    font-family: 'reg', sans-serif;
    letter-spacing: 0.8px;
    letter-spacing: 0.4;
    line-height: 1.2;
    font-weight: 100;
    width: 100vw;
    background-color: $red;

}


// body {

//     background-color: $red;
//     color: $white;
//     margin: 30px;

//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     height: calc(100vh - 168px);


//     @include sizetwo {

//         height: 100%;
//         margin: 15px;

//     }

// }

// PREV PART

body {

    background-color: $white;
    color: $black;
    padding: 30px;
    font-family: 'med';
    letter-Spacing: 0.6px;
    font-size: 17px;
    line-height: 1.25;

    @include sizeone {

        height: 100%;
        padding: 15px;

    }

}

.tablet.portrait body  {

    padding: 30px;

}

.desk {

    @include sizetwo {

        display: none!important;

    }

}

.mob, .mob-flex {

    display: none!important;

}

.mob {

    @include sizetwo {

        display: block!important;

    }
}

.mob-flex {

    display: none!important;

    @include sizetwo {

        display: flex!important;

    }

}
h1 {

    font-family: 'hello';
    font-size: 40px;
    // font-size: 70px;
    text-transform: uppercase;
    line-height: 1;
    color: $red;
    z-index: 1000;


    // @include sizetwo {

    //     font-size: 46px;
    //     transform: translateY(5px);
    //     line-height: 1;

    // }
}

h2 {

    font-family: 'hello';
    text-transform: uppercase;
    font-size: 70px;
    line-height: 1;

    @include sizeone {

        font-size: 46px;
        transform: translateY(5px);
        line-height: 1;

    }

}

h3 {

    font-size: 32px;
    font-family: 'ext125';
    text-transform: uppercase;

    @include sizeone {

        font-size: 21px;

    }

}

h4 {

    font-family: 'ext200';
    text-transform: uppercase;
    font-size: 40px;

    @include sizeone {

        font-size: 27px;

    }

}

h5 {

    font-family: 'ext125';
    text-transform: uppercase;
    // font-size: 20px;
    font-size: 17px;
    line-height: 1.25;

    @include sizeone {

        font-size: 16px;
        margin-top: 14px;

    }

    strong {

        font-family: 'ext200';

    }

}

h6 {

    font-family: 'ext125';
    text-transform: uppercase;
    font-size: 12px;

}

.chiff {

    svg {

        width: 30px;
        height: 30px;

        path {

            fill:$black;

        }

    }

}

