/* ------------------------------------------------------------------ */
/*        FONT FACE DECLARATION
/* ------------------------------------------------------------------ */


@font-face {
    font-family: 'hello';
    src: url(https://maisonjar.nyc/assets/fonts/HelveticaRoundedLTPro-Blk.woff2),
         url(https://maisonjar.nyc/assets/fonts/HelveticaRoundedLTPro-Blk.woff) format('woff'),
         url(https://maisonjar.nyc/assets/fonts/HelveticaRoundedLTPro-Blk.otf) format('opentype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'med';
    src: url(https://maisonjar.nyc/assets/fonts/SyndicatGrotesk-Medium.woff2),
         url(https://maisonjar.nyc/assets/fonts/SyndicatGrotesk-Medium.woff) format('woff'),
         url(https://maisonjar.nyc/assets/fonts/SyndicatGrotesk-Medium.otf) format('opentype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'bold';
    src: url(https://maisonjar.nyc/assets/fonts/SyndicatGrotesk-Bold.woff2),
         url(https://maisonjar.nyc/assets/fonts/SyndicatGrotesk-Bold.woff) format('woff'),
         url(https://maisonjar.nyc/assets/fonts/SyndicatGrotesk-Bold.otf) format('opentype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'ext200';
    src: url(https://maisonjar.nyc/assets/fonts/MaisonJar-200.woff2),
         url(https://maisonjar.nyc/assets/fonts/MaisonJar-200.woff) format('woff'),
         url(https://maisonjar.nyc/assets/fonts/MaisonJar-200.otf) format('opentype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'ext200';
    src: url(https://maisonjar.nyc/assets/fonts/MaisonJar-200Italic.woff2),
         url(https://maisonjar.nyc/assets/fonts/MaisonJar-200Italic.woff) format('woff'),
         url(https://maisonjar.nyc/assets/fonts/MaisonJar-200Italic.otf) format('opentype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'ext125';
    src: url(https://maisonjar.nyc/assets/fonts/MaisonJar-125.woff2),
         url(https://maisonjar.nyc/assets/fonts/MaisonJar-125.woff) format('woff'),
         url(https://maisonjar.nyc/assets/fonts/MaisonJar-125.otf) format('opentype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'ext125';
    src: url(https://maisonjar.nyc/assets/fonts/MaisonJar-125Italic.woff2),
         url(https://maisonjar.nyc/assets/fonts/MaisonJar-125Italic.woff) format('woff'),
         url(https://maisonjar.nyc/assets/fonts/MaisonJar-125Italic.otf) format('opentype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'chiff';
    src: url(https://maisonjar.nyc/assets/fonts/MaisonJar-Chiffre.woff);
}
