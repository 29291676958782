/* ------------------------------------------------------------------ */
/*        LANDING
/* ------------------------------------------------------------------ */
// * {
//     outline: 0;}

// .hero, .content {

//     text-align: center;

// }

// .hero {

//     margin-bottom: 98px;

//     @include sizetwo {

//         margin-bottom: 0px;
//         justify-content: flex-end;
//         padding-bottom: 5vh;

//     }

// }

// .content {

//     @include sizetwo {

//         justify-content: flex-start;
//         padding-top: 3vh;

//     }

// }

// .hero, .content {

//     @include sizetwo {

//         height: 45vh;
//         margin-bottom: 0px;
//         display: flex;
//         flex-direction: column;

//     }

// }

/* ------------------------------------------------------------------ */
/*        STORE
/* ------------------------------------------------------------------ */
#cartoon_gif {

    position: fixed !important;
    width: 220px;
    top: calc(100vh - 155px);
    left: calc(100vw - 225px);
    z-index: 1000;
    cursor: grab;

    &.ui-draggable-dragging {

        cursor: grabbing;

    }

    img {

        width: 100%;
        height: 100%;
        object-fit: cover;

    }

    @include sizetwo {

        display: none;

    }

}

.big_text {

    font-family: 'hello';
    font-size: 43px;
    line-height: 1.1;
    letter-spacing: -0.1px;
    margin-bottom: 10px;


    @include sizeone {

        font-size: 30px;
        margin-bottom: 10px;

    }
}


.small_text {

    width: 60%;
    margin: auto;
    margin-bottom: 30px;

    @include sizeone {

        width: 100%;
        margin-bottom: 20px;

    }

}

.tablet.portrait .small_text {

    width: 100%;

}

.main-carousel {

    height: 62vh;
    margin-bottom: 22px;
    transform: translateX(-30px);
    width: calc(100% + 60px);

    @include sizetwo {

        height: 50vh;

    }

    @include sizeone {

        width: calc(100% + 30px);
        transform: translateX(-15px);

    }

    .carousel-cell,
    .flickity-viewport {

        height: 66vh;
        padding-left: 10px;

        @include sizetwo {

            height: 50vh;

        }

        img {

            height: calc(66vh - 53px);
            width: auto;
            // object-fit: cover;
            border-radius: 20px;

            @include sizetwo {

                height: calc(50vh - 28px);

            }
        }
    }
}

legend {

    margin-left: 18px;
    margin-top: 2px;
    position: absolute;
    width: fit-content;

}

.partners legend,
.products legend {

    position: static !important;

}

.wrapper_image {

    width: 40%;
    margin: auto;
    margin-bottom: 20px;

    @include sizetwo {

        width: 60%;

    }

    @include sizeone {

        width: 100%;

    }

    .cls-3 {

        width: 100%;
        object-fit: cover;
        object-position: center;

    }

}

.tablet.portrait .wrapper_image {

    width: 60%;

}



.store {

    padding-top: 110px;

    h2 {

        color: $red;
        text-align: center;

    }

    &_infos {

        display: flex;
        justify-content: space-between;
        text-align: center;
        color: $red;
        padding: 25px;
        padding-top: 70px;
        padding-bottom: 72px;

        a:hover {

            color: $black !important;

        }

        @include sizeone {

            flex-direction: column;

        }

        &_item {

            width: calc(100% / 3);
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            @include sizeone {

                width: 100%;
                padding-top: 10px;
                padding-bottom: 10px;

            }

            h5 {

                margin-top: 0px;
            }


        }


    }
}

.tablet.portrait .store {

    .store_infos {

        flex-direction: row;

        &_item {

            width: calc(100% / 3);
            padding-top: 0px;
            padding-bottom: 0px;

        }

    }

}

.store {

    .store_process {

        display: flex;
        justify-content: space-between;
        margin-top: 50px;

        @include sizeone {

            flex-direction: column;

        }

        &_item {

            width: calc(100% / 3);
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            @include sizeone {

                width: 100%;

            }

            img {

                width: 115px;
                height: auto;
                object-fit: cover;
                margin-bottom: 30px;

                @include sizeone {

                    margin-bottom: 10px;

                }

            }

            h5 {

                margin-top: 0px;
            }

        }

    }

}

.tablet.portrait .store {

    .store_process {

        flex-direction: row;

        &_item {

            width: calc(100% / 3);

        }

    }

}

.mission,
.partners,
.products,
.workshops {

    padding-top: 100px;

    @include sizetwo {

        padding-top: 70px;

    }

    .title_part {

        padding-bottom: 70px;
        text-align: center;
        color: $red;

    }

    em {

        font-style: italic;

    }

}

.mission {

    .small_text {

        margin-bottom: 0px;

    }

}

.partners {

    &_list {

        font-family: "ext125";
        font-size: 16px;
        line-height: 1.2;
        columns: 3;
        text-align: left;
        margin: auto;

        @include sizetwo {

            columns: 2;

        }

        @include sizeone {

            columns: 1;

        }

    }

}

.tablet.portrait .partners {

    &_list {

        columns: 2;

    }

}

.products {

    &_list {

        text-align: left;
        columns: 3;

        @include sizeone {

            columns: 1;

        }

        &_item {

            margin-bottom: 30px;

            @include sizetwo {

                margin-bottom: 5px;

            }

        }

        .title_category {

            @include sizetwo {

                position: relative;

            }

            h5 {

                @include sizetwo {

                    margin-top: 0px;

                }

            }

            .cross {

                position: absolute;
                top: 5px;
                right: 0px;
                height: 10px;
                width: 10px;
                transform: rotate(45deg);
                transition: 0.2s transform ease;
                fill: $black;

            }

        }

        .products_wrapper {

            @include sizetwo {

                height: 0px;
                overflow: hidden;

            }

        }

    }

    .products_list_item.open .cross {

        transform: rotate(0deg);

    }

}

.tablet.portrait .products {

    &_list {

        columns: 2;

    }

}

.workshops {

    .events {

        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 25px);

        @include sizetwo {

            width: calc(100% + 20px);

        }

        @include sizeone {

            flex-direction: column;
            width: 100%;

        }

        .event {

            width: calc((100% / 3) - 25px);
            margin-right: 25px;
            color: $red;
            margin-bottom: 30px;

            @include sizetwo {

                width: calc((100% / 2) - 25px);

            }

            @include sizeone {

                margin-bottom: 20px;
                width: 100%;
                margin-right: 0px;

            }

            &_dates,
            .link {

                background-color: $red;
                padding: 3px 7px;
                border-radius: 5px;
                color: $white;
                display: block;
                width: fit-content;
                margin-top: 3px;
                text-transform: uppercase;

            }

            &_dates {

                width: 100%;
                margin-bottom: 3px;

            }

            &.date_past {

                color: $black;

                .event_dates {

                    background-color: $black;

                }

                .link {

                    display: none;

                }

            }

            h5 {

                margin-top: 0px;

            }

        }

    }

}

.tablet.portrait .workshops {

    .events {

        width: calc(100% + 20px);
        flex-direction: row;

    }

    .event {

        width: calc((100% / 2) - 25px);

    }

}


.store,
.mission,
.partners,
.products,
.workshops {

    opacity: 0;
    transform: translateY(20px);

    transition: opacity 0.3s ease,
        transform 0.3s ease;

    @include sizetwo {

        opacity: 1;
        transform: translateY(0px);

    }

    &.appeared {

        opacity: 1;
        transform: translateY(0px);

    }

}