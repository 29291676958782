$b-height: 60px; //Height (and width) of the burger menu
$b-margin-top: 30px; //Burger menu margin (distance from top)
$b-margin-left: 30px; //Burger menu margin (distance from left)
$b-border-width: 2px; //Width of the borders and 'buns'
$b-bun-width: 25px; //Width of the 'buns'
$b-bun-height: $b-border-width; //Height of the 'buns'
$b-border-radius: 50%; //Change the border radius of the menu

$b-trans-speed: .4s; //Transition speed settings
$b-transition: all $b-trans-speed ease; //Transitions

$b-font-size: 24px; //Font size of brand and links
$b-font-family: Helvetica-Neue, Helvetica, Arial, sans-serif; //Font family of the brand and links
$b-font-weight: 300; //Font weight of brand and links
$b-txt-pad: 30px; //Left padding on both brand and links

$b-line-height: ($b-height / 2 - ($b-font-size / 2.8)); //Vertically centers brand to menu
$b-txt-pad-top: ($b-margin-top + $b-height + $b-txt-pad); //Top margin for first link element
$b-bun-width-half: ($b-bun-width / 2); //Half bun width used for calculation
$b-pad-left: ($b-height / 2 - $b-bun-width-half - 2); //Horizontally centers bun in menu
$b-pad-top: ($b-height / 2 - $b-bun-width-half); //Vertically centers bun in menu

// @keyframes slideInLeft {
//   0% {
//     transform: translate3d(-250px, 0, 0);
//     visibility: visible;
//   }

//   100% {
//     transform: translate3d(0, 0, 0);
//   }
// }

// @keyframes slideOutLeft {
//   0% {
//     transform: translate3d(0, 0, 0);
//   }

//   100% {
//     transform: translate3d(-250px, 0, 0);
//     visibility: hidden;
//   }
// }

* {
  box-sizing: border-box;
}

.b-nav {

  z-index: 11;
  height: calc(100vh - 25px);
  width: 100vw;
  overflow: scroll;
  padding-bottom: 40px;
  display:none;

  .wrapper_nav {

    overflow: scroll;
    height: 200vh;

  }

  &:not(.open) {
    visibility: hidden;
  }

  li {
    color: $red;
    list-style-type: none;
    padding: 12px 20px;
    padding-left: 0px;
    text-align: left;
    transform: translateX(-250px);
    text-transform: uppercase;
    width: calc(100vw - 24px);
    text-align: center;

      .title {

        font-size: 1.8rem!important;

      }

  }

  li:first-child {
    padding-top: 12px;
  }
  li:last-child {
    padding-top: 52px;
  }

  &.open {
    visibility: visible;
  }
}

.layout_nav {

  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: $black;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in;

  &.open {

    opacity: 1;

  }

}

.b-link {
  background: transparent;
  border-left: color(complement, normal, 0) solid $b-border-width;
  color: $red;
  font-family: $b-font-family;
  font-size: $b-font-size;
  font-weight: $b-font-weight;
  margin-left: $b-margin-left;
  text-decoration: none;
  transition: $b-transition;
  width: auto;

  &:hover,
  &--active {
    border-left: $red solid $b-border-width;
    // padding-left: $b-txt-pad;
  }
}

.b-menu {
  border: none;
    cursor: pointer;
    display: inline-block;
    height: 60px;
    position: relative;
    transition: background-color 0.3s ease-in;
    user-select: none;
    width: 60px;
    z-index: 12;
    border-radius: 20px;

}

.b-bun {
  background: $red;
  position: relative;
  transition: $b-transition;
  background-color: $red;

  &--top {
    height: $b-bun-height;
    top: 0;
    width: $b-bun-width;
  }

  &--mid {
    height: $b-bun-height;
    top: 8px;
    width: $b-bun-width;
  }

  &--bottom {
    height: $b-bun-height;
    top: 16px;
    width: $b-bun-width;
  }
}

.b-brand {
  color: $red;
  font-family: $b-font-family;
  font-size: $b-font-size;
  font-weight: $b-font-weight;
  margin-left: $b-txt-pad;
  position: relative;
  text-decoration: none;
  top: -$b-line-height;
  transition: $b-transition;
  z-index: 13;
}

.b-container {
  height: $b-height;
  position: absolute;
  transition: background 0.3s ease-in;
  -webkit-transition: background 0.3s ease-in;
  -moz-transition: background 0.3s ease-in;
  -ms-transition: background 0.3s ease-in;
  -o-transition: background 0.3s ease-in;
  top: -5px;
  right: 16px;
  padding: 15px;

  @include sizeone {


  }


  &:hover:not(.open) {
    .bun-top,
    .bun-mid,
    .bun-bottom {
      background: $red;
    }
  }

  &.open {
    .b-main {
      border: $red solid $b-border-width;
    }

    .b-bun--top {
      background: $red;
      top: 9px;
      transform: rotate(45deg);
    }

    .b-bun--mid {
      opacity: 0;
    }

    .b-bun--bottom {
      background: $red;
      top: 5px;
      transform: rotate(-45deg);
    }

    .b-brand {
      color: $red;
    }

  }
}

body.nav_open .b-nav {

  @include sizetwo {

    display:block;

  }

}