/* ------------------------------------------------------------------ */
/*        General HTML - BODY
/* ------------------------------------------------------------------ */
// * {
//     outline: none;
//     -ms-overflow-style: none;  // IE 10+
//     overflow: -moz-scrollbars-none;  // Firefox
//     &::-webkit-scrollbar {
//         display: none;
//     }
// }

/* ------------------------------------------------------------------ */
/*        HEADER
/* ------------------------------------------------------------------ */

header {

    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'ext125';
    text-transform: uppercase;
    font-size: 12px;

    position: fixed;
    top: 30px;
    left: 30px;
    width: calc(100% - 60px);
    z-index: 10000;

    transition: top 0.3s ease-in;

    &.hide {

        top: -40px;

    }

    @include sizeone {

        width: calc(100% - 30px);
        top: 15px;
        left: 15px;

    }

    .burger {

        width: 38px;
        height: 38px;
        z-index: 1000;

        #house {

            fill: $red;
            width: 100%;
    
        }

    }

    .nav {
        position: relative;
        
        ul {

            display: flex;
            background-color: $white;
            border-radius: 5px;
            height: 21px;

            li {

                padding: 3px 23px;
                z-index: 100;
                transition: color 0.2s 0.5s;
                line-height: 1.3;
                cursor: pointer;

                &.selected {

                    color: $white;

                }

            }
        }

        .selector {

            background-color: $red;
            border-radius: 5px;
            position: absolute;
            height: 21px;
            width: 90px;
            top: 0px;
            left: 0px;

            transition: opacity 0.4s ease-in 0.3s;

            &.hide {

                opacity: 0;

            }
            
        }

        @include sizetwo {

            display: none;
    
        }

        &_mob {

            position: fixed;
            top: 0px;
            left: 0px;

            width: 100vw;
            height: 100vh;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            text-align: center;

            pointer-events: none;

            background-color: $white;

            
            // transform: translateY(-60vh);
            opacity: 0;
            z-index: -1;
    
            transition: transform 0.5s ease-out,
                        opacity 0.4s ease-out;

            &.open {

                // transform: translateY(0px);
                opacity: 1;
                z-index: 100;

                pointer-events: auto;
        
            }

            .nav_item {

                font-family:'hello';
                text-transform: uppercase;
                font-size: 46px;
                color: $red;
                line-height: 1;
                transform: rotateY(100px);
                opacity: 0;

                &:active, &:focus {

                    color: $black;

                }

            }

        }

       

    }

    .button_contact {

        background-color: $white;
        border-radius: 7px;
        padding: 3px 23px;
        height: fit-content;
        cursor: pointer;

        transition: background-color 0.4s ease-in 0.3s,
                    color 0.3s ease-in;

        &.selected {

            background-color: $red;
            color: $white;

        }

        @include sizetwo {

            display: none;
    
        }

    }

}

.tablet.portrait header  {

    top: 30px;
    left: 30px;
    width: calc(100% - 60px);

    .b-container {

        right: 3px;

    }

}

.mobile .nav_mob, .tablet.portrait .nav_mob {

    height: var(--vh);

}

body.nav_mob_open {

    position: fixed;

    header {

        top: 15px!important;

    }

}

.tablet.portrait body.nav_mob_open header {

    top: 30px!important;

}

/* ------------------------------------------------------------------ */
/*        FOOTER
/* ------------------------------------------------------------------ */

.footer, footer {

    // position: absolute;
    // bottom: 30px;
    // left: 15px;
    // width: calc(100% - 60px);
    color:$red;
    padding-top: 100px;
    display: flex;
    position: relative;

    a:hover {

        color: $black!important;
                
    }

    @include sizetwo {

        display: block;
        columns: 2;

    }

    @include sizeone {

        display: flex;
        flex-direction: column;
        width: 100%;

    }

    &_part {

        width: calc(100% / 3);
        margin-right: 30px;

        @include sizetwo {

            width: calc(100% - 15px);

        }

        @include sizeone {

            width: 100%;
            margin: 0px;
    
        }

        &:first-child {

            @include sizetwo {

                margin-bottom: 27px;

            }

            @include sizeone {

                margin-bottom: 0px;

            }

        }
    }

    .contact_part {

        display: flex;
        flex-direction: column;

        svg {

            margin-top: 6px;

            path {
                fill: $black;
            }

            @include sizeone {

                margin-top: 15px;
                width: 27px;
                height: 27px;
                // fill: $black;

            }

        }

    }

    h6 {

        margin-top: 3px;

        @include sizeone {

            font-size: 16px;

        }

    }

    input {

        color: $red!important;

    }

    input:-internal-autofill-selected {

        color: $white!important

    }

    input:matches([type="button"], [type="submit"], [type="reset"]):active, input[type="file"]::file-selector-button:active, button:active {

        color: $red!important

    }


    #mc_embed_signup_scroll {

        display: flex;
        align-items: center;
        position: relative;

        #mce-EMAIL {

            background-color: transparent;
            border: 2px solid $red;
            border-radius: 7px;
            margin-top: 5px;
            padding-top: 3px;
            padding-bottom: 4px;
            padding-left: 8px;
            padding-right: 8px;
            width: 100%;
            font-family: "med";

            &::placeholder {

                font-family: "med";
                letter-spacing: 0.6px;
                font-size: 15px;
                color: $red;

            }

        }

        #mc-embedded-subscribe {

            font-family: "ext125";
            text-transform: uppercase;
            font-size: 16px;
            line-height: 1.2;

            border: 0px;
            background-color: transparent;
            color: $red!important;

            &:hover {

                color: $black!important;

            }
            @include sizetwo {

                padding-right: 0px;
                padding-left: 10px;
                        
            }

        }

        #mce-responses {

            display: block;
            position: absolute;
            top: 37px;
            left: 0px;

            a {

                display: none;

            }
            
        }

    }

    #instagram {

        position: absolute;
        left: calc(100% / 3);
        bottom: -5px;
        margin-bottom: 6.5px;

        path {

            fill: $red;

        }

        @include sizetwo {

            margin-bottom: 0px;
            right: 1px;
            left: unset;
            bottom: 0px;

        }

    }
    
}

.tablet.portrait footer,  .tablet.portrait .footer{

    flex-direction: row;

    &_part {

        width: calc(100% - 15px);
        margin-right: 30px;

    }

}

.credits_site {

    display: flex;
    margin-top: 25px;
    padding-right: 10px;
    color: $red;


    @include sizeone {

        flex-direction: column;

    }

    .credits_item {

        width: calc(100% / 3);
        display: flex;

        @include sizethree {

            flex-direction: column;

        }

        @include sizeone {

            width: 100%;

        }

        h5 {


            margin-right: 10px;
        }

        a:hover {

            color: $black!important;

        }

    }

}



/* ------------------------------------------------------------------ */
/*        Preloader
/* ------------------------------------------------------------------ */
// .banner {

//     position: fixed;
//     width: 400px;
//     height: 200px;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     background-color: #f8f8f8;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     padding: 30px;
//     text-align: center;
//     padding-bottom: 0px;
//     border-radius: 10px;
//     -webkit-box-shadow: 0px 1px 11px -3px #2e2e2e;
//     box-shadow: 0px 1px 11px -3px #2e2e2e;
//     z-index: 1000000;
//     display: none;

//     @include sizetwo {

//         width: 88vw;

//     }

//     &.show {

//         display: flex;

//     }

//     a {

//         position: relative;

//         .under {

//             background-color: black;
//             width: 100%;
//             position: absolute;
//             bottom: -1px;
//             left: -1px;
//             height: 1px;
//             transform: scaleX(1);
//             transform-origin: 0% 0;
//             pointer-events: none;
//             transition: transform 0.3s $Power3EaseInOut,
//                         background-color 0.3s $Power3EaseInOut;

//         }

//         &:hover {

//             .under {

//                 transform: scaleX(0);
//                 transform-origin: 100% 0;

//             }

//         }

//     }

// }

.preloader{

    width: 100%;
    height: 100vh;
    color: $white;
    background-color: $red;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    .preload_content {

        opacity: 0;
        // transform: rotateY(50deg);

    }

}

.mobile .preloader, .tablet .preloader {

    height: var(--vh);

}

/* ------------------------------------------------------------------ */
/*        404
/* ------------------------------------------------------------------ */

.error_page {

    height: calc(100vh - 313px);

    .error {

        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        a {

            text-transform: uppercase;
        }

    }

}